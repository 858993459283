<template>
  <span />
</template>
<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ReadingArea.vue');
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

import LayoutManager from '@/services/utils/LayoutManager';
import Utils from '@/services/utils/Utils';
import LayoutUtils from '@shared/publication/dom-utils/layout-utils.js';
import ReadingAreaEvents from '@/enums/ReadingAreaEvents';
import Locator from '@shared/publication/locator.js';
import TextUtils from '@shared/publication/dom-utils/text-utils.js';
import BookUtils from '@shared/publication/book-utils';
import { isHeader } from '@shared/publication/dom-utils/marker-utils';
import BookScrollEnum from '@/enums/BookScrollEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';

const READING_AREA_ID = 'readingAreaId';
const SEARCH_DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};
export default {
  data() {
    return {
      viewport: {},
      readingArea: {},
      endSubscription: null,
      retryTimeOut: null
    };
  },
  computed: {
    onDeviceOpen() {
      const isDevice = this.$store.getters['ContextStore/isDevice'];
      const isDeviceBrowser = this.$store.getters[
        'ContextStore/isDeviceBrowser'
      ];
      return isDevice || isDeviceBrowser;
    },
    isMetaBlockInViewport() {
      return this.$store.getters['ContextStore/getIsMetaBlockInViewport'];
    },
    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    },
    publicationType() {
      return this.$store.getters['OpenParameterStore/getPublicationType'];
    },
    isViewMode() {
      return this.$store.getters['OpenParameterStore/isPreviewMode'];
    },
    isApplyAllParams() {
      return this.$store.getters['PublicationStore/isApplyAllParams'];
    },
    transitionOver() {
      return this.$store.getters['PublicationStore/isTransitionOver'];
    },
    isCompilationEditMode() {
      return this.$store.state.CompilationsStore.isEditMode;
    },
    isSupportedState() {
      const appState = this.$store.getters['ContextStore/appState'];
      return [
        AppStateEnum.PRESENT_PUBLICATION,
        AppStateEnum.MANAGE_ABOUT
      ].includes(appState);
    }
  },
  watch: {
    async isApplyAllParams(val) {
      if (val && this.isSupportedState) {
        await this.callUpdateReadingArea();
      }
    }
  },
  mounted() {
    this.endSubscription = this.$store.subscribe(async mutation => {
      if (mutation.type === 'PublicationStore/setBookScrollEvent') {
        if (
          [BookScrollEnum.STOP, BookScrollEnum.STABLE].includes(
            mutation.payload?.type
          ) &&
          this.isApplyAllParams &&
          this.isSupportedState
        ) {
          const eventData = mutation?.payload?.data;
          const isTooFar = this.$_callIsTooFar(eventData);
          if (!isTooFar) {
            const layoutChange = false;
            await this.callUpdateReadingArea(layoutChange, eventData);
          }
        }
      } else if (
        mutation.type === 'ContextStore/setWebBackgroundMode' &&
        mutation.payload
      ) {
        await this.callUpdateReadingArea();
      }
    });
    LayoutManager.register({
      layout: this.$_onLayoutChange,
      id: READING_AREA_ID
    });
  },
  destroyed() {
    this.$_clearRetryTimeout();
    if (this.endSubscription) {
      this.endSubscription();
    }
    this.$store.dispatch('ProgressStore/clearReadingArea');
    LayoutManager.unregister(READING_AREA_ID);
  },
  methods: {
    $_callIsTooFar(eventData) {
      const isTooFar = eventData?.isTooFar;
      if (typeof isTooFar === 'function') {
        return isTooFar();
      }
      return false;
    },
    async callUpdateReadingArea(layoutChange, eventData) {
      try {
        await this.updateReadingArea(layoutChange, eventData);
      } catch (err) {
        this.$_readingAreaErrorHandler(err);
      }
    },
    async $_onLayoutChange(payload) {
      const { events } = payload;
      const isScrolling = this.$store.getters['ContextStore/getIsScrolling'];

      if (
        !isScrolling &&
        !this.onDeviceOpen &&
        events.resizing &&
        this.transitionOver &&
        this.isSupportedState
      ) {
        const layoutChange = true;
        await this.callUpdateReadingArea(layoutChange);
      }
    },
    updateViewportRectangle() {
      try {
        const viewportRect = this.$store.getters[
          'PublicationStore/getViewportRect'
        ]();
        if (!viewportRect) {
          return;
        }
        const safeAreaBottom = Utils.getSafeAreaInsetBottom();
        const bottomCorrection = AppConstantsUtil.PROGRESS_TOOLBAR_HEIGHT;
        const topCorrection = this.isViewMode ? viewportRect.top : 0;

        const viewport = {
          top: viewportRect.top || 0,
          left: 0,
          right: window.innerWidth,
          bottom:
            viewportRect.height -
            bottomCorrection +
            topCorrection -
            safeAreaBottom
        };
        Object.assign(this.viewport, viewport);
      } catch (err) {
        this.$_readingAreaErrorHandler(err);
      }
    },

    $_findElementLogicalPositionByPoint(x, y, searchDirection) {
      const paraSelector = '[data-ww]';
      const paraSelectors = [
        paraSelector,
        `[${AppConstantsUtil.WORD_COUNT_ATTRIBUTE}]`,
        `[${AppConstantsUtil.ILM_ID_ATTRIBUTE}]`
      ];

      let elements = [];
      try {
        elements = document.elementsFromPoint(x, y);
      } catch (error) {
        if (
          !error?.message?.includes('The provided double value is non-finite')
        ) {
          throw error;
        }
      }

      const metablock = elements.find(
        el =>
          el.classList.contains(AppConstantsUtil.META_BLOCK_CLASS) ||
          el.classList.contains(AppConstantsUtil.META_BLOCK_WRAPPER_CLASS)
      );

      const suggestedBlock = elements.find(
        el =>
          el.classList.contains(AppConstantsUtil.SUGGESTED_BLOCK_CLASS) ||
          el.classList.contains(AppConstantsUtil.LAST_PARA)
      );

      let el;
      if (metablock) {
        const firstParaId = this.$store.getters[
          'PublicationStore/getFirstContentParagraph'
        ]();
        el = document.querySelector(`#${firstParaId}`);
        const safeAreaBottom = Utils.getSafeAreaInsetBottom();
        const elementY =
          Math.round(el?.getBoundingClientRect()?.y) + safeAreaBottom;
        y = Number.isInteger(elementY) ? elementY : y;
        const isOutOfViewport = y > this.viewport.bottom;
        if (isOutOfViewport) {
          return {};
        }
        if (!el && searchDirection === SEARCH_DIRECTION.TOP) {
          //not all element rendered
          return {};
        }
      } else if (suggestedBlock) {
        const lastParaId = this.$store.getters[
          'PublicationStore/getLastContentParagraph'
        ]();
        el = document.querySelector(`#${lastParaId}`);
      } else {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const isScrollElement = element.classList.contains(
            AppConstantsUtil.SCROLL_ITEM_CLASS
          );
          if (!isScrollElement) {
            continue;
          }
          for (let j = 0; j < paraSelectors.length; j++) {
            const selector = paraSelectors[j];
            el = element.querySelector(
              `${selector}:not([epub\\:type="footnote"])`
            );
            if (el) {
              break;
            }
          }

          if (el) {
            break;
          }
        }
      }
      if (!el && searchDirection === SEARCH_DIRECTION.BOTTOM) {
        const paragraphs = document.querySelectorAll(paraSelector);
        el = paragraphs[paragraphs.length - 1]
          ? paragraphs[paragraphs.length - 1]
          : el;
        const rects = el?.getBoundingClientRect();
        const elementY = Math.round(rects?.y + rects?.height);
        y = Number.isInteger(elementY) ? elementY : y;
      }

      if (!el) {
        const paragraphs = document.querySelectorAll(paraSelector);
        const elementsClasses = [...elements].map(element => element.classList);
        const paraIds = [...paragraphs].map(para => para.id);
        const safeAreaBottom = Utils.getSafeAreaInsetBottom();
        const safeAreaInsetTop = Utils.getSafeAreaInsetTop();
        const scrollTop = this.$_getScrollTop();
        const err = new Error(`Can't calculate reading area
            x: ${x}, y: ${y}
            searchDirection: ${searchDirection}
            innerWidth: ${window.innerWidth}
            innerHeight: ${window.innerHeight}
            scrollTop: ${scrollTop}
            safeAreaInsetTop: ${safeAreaInsetTop}
            safeAreaBottom: ${safeAreaBottom}
            publicationId: ${this.publicationId}
            paraSelectors: ${paraSelectors}
              paraIds:${paraIds[0]}-${paraIds[paraIds.length - 1]}
            elements: ${elementsClasses}`);
        err.type = CustomErrorEnum.CANCEL_CALC_READING_AREA;
        throw err;
      }
      const paraId = el.id;
      let wordsStableOffsets = this.$store.getters[
        'PublicationStore/getParaWordsStableOffsets'
      ](this.publicationId, paraId);
      if (!wordsStableOffsets) {
        const preparedText = TextUtils.extractContent(el);
        wordsStableOffsets = TextUtils.collectWordsStableOffsets(preparedText);
        this.$store.commit('PublicationStore/setWordsStableOffsets', {
          publicationId: this.publicationId,
          paraId,
          wordsStableOffsets
        });
      }

      const dir = this.$_getParaElementDirection(el) || 'ltr';
      const wordX = this.$_getWordX(el, dir, searchDirection) || x;
      if (searchDirection === SEARCH_DIRECTION.TOP && !metablock && el) {
        const rects = el.getBoundingClientRect();
        y = Math.max(y, rects.y);
      }

      const wordLocator = BookUtils._findWordLocator(el, wordX, y);
      const wordOffset = [
        wordLocator?.startLocator?.logicalCharOffset,
        wordLocator?.endLocator?.logicalCharOffset
      ];
      let wordOffsetIndex = wordsStableOffsets.findIndex(
        offset => wordOffset[0] === offset[0] && wordOffset[1] === offset[1]
      );

      //to fix case play from meta block on small screens when the play button displays over paragraph
      //It provides an error in the calculation reading area
      if (
        wordOffsetIndex === -1 &&
        isHeader(el) &&
        searchDirection === SEARCH_DIRECTION.TOP
      ) {
        const titleWordLocator = BookUtils._findWordLocator(el, wordX, y + 20);
        const titleWordOffset = [
          titleWordLocator?.startLocator?.logicalCharOffset,
          titleWordLocator?.endLocator?.logicalCharOffset
        ];
        wordOffsetIndex = wordsStableOffsets.findIndex(
          offset =>
            titleWordOffset[0] === offset[0] && titleWordOffset[1] === offset[1]
        );
      }
      const defaultValue =
        metablock && searchDirection === SEARCH_DIRECTION.TOP
          ? wordsStableOffsets[0]
          : wordsStableOffsets[wordsStableOffsets.length - 1];

      const stableCharOffset = wordsStableOffsets[wordOffsetIndex] ||
        defaultValue || [0, 0];
      return {
        element: el,
        stableCharOffset
      };
    },
    $_getScrollTop() {
      try {
        return this.$store.getters[
          'PublicationStore/getScrollTopAbsolutePos'
        ]();
      } catch (error) {
        logger.warn(`get error on get scroll top: ${error}`);
        return -1;
      }
    },

    $_getParaElementDirection(el) {
      const paraItem = el.closest(`.${AppConstantsUtil.SCROLL_ITEM_CLASS}`);
      const dir = paraItem ? paraItem.getAttribute('dir') : null;
      return dir;
    },
    $_getWordX(el, dir, searchDirection) {
      const rect = el.getBoundingClientRect();
      let wordX = null;
      if (searchDirection === SEARCH_DIRECTION.TOP) {
        wordX = dir === 'ltr' ? rect.x : rect.x + rect.width;
      } else if (searchDirection === SEARCH_DIRECTION.BOTTOM) {
        wordX = dir === 'ltr' ? rect.x + rect.width : rect.x;
      }
      return wordX;
    },
    async updateReadingArea(layoutChange, eventData, numRetry = 3) {
      try {
        this.$_clearRetryTimeout();
        if (!window.innerWidth || !this.$_isReadingAreaVisible()) {
          return;
        }
        this.updateViewportRectangle();
        const topReadingPosition = this.$_findElementLogicalPositionByPoint(
          window.innerWidth / 2,
          this.viewport.top,
          SEARCH_DIRECTION.TOP
        );
        const bottomReadingPosition = this.$_findElementLogicalPositionByPoint(
          window.innerWidth / 2,
          this.viewport.bottom,
          SEARCH_DIRECTION.BOTTOM
        );
        if (!bottomReadingPosition.element || !topReadingPosition.element) {
          return;
        }
        let topLocator = LayoutUtils.convertPositionToScalarLocator(
          topReadingPosition,
          true
        );
        let bottomLocator = LayoutUtils.convertPositionToScalarLocator(
          bottomReadingPosition,
          false
        );
        const isBottomBeforeTopLocator = bottomLocator.precedes(topLocator);
        if (isBottomBeforeTopLocator) {
          const tmpTopLocator = topLocator;
          topLocator = bottomLocator;
          bottomLocator = tmpTopLocator;
        }

        this.readingArea = new Locator.RangeLocator(topLocator, bottomLocator);

        const isRotateScroll = this.$store.getters[
          'PublicationStore/isRotateScroll'
        ];
        if (!layoutChange && !isRotateScroll) {
          this.$store.commit('ProgressStore/setReturnReadingArea', {
            readingArea: this.readingArea
          });
        }
        this.$store.commit('PublicationStore/setRotateScroll', false);

        await this.$store.dispatch('ProgressStore/updateReadingArea', {
          readingArea: this.readingArea,
          publicationId: this.publicationId,
          type: this.publicationType
        });
      } catch (error) {
        if (error?.type === CustomErrorEnum.CANCEL_CALC_READING_AREA) {
          const isTooFar = this.$_callIsTooFar(eventData);
          if (isTooFar) {
            logger.warn(
              `We scroll out of viewport during calculate reading are`
            );
            return;
          }
          const retryTime = this.onDeviceOpen ? 800 : 500;
          numRetry -= 1;
          if (numRetry > 0) {
            logger.warn(
              `Set retry calculate reading are after ${retryTime} numRetry: ${numRetry}`
            );
            this.retryTimeOut = setTimeout(() => {
              this.updateReadingArea(layoutChange, eventData, numRetry);
            }, retryTime);
            return;
          }
        }
        logger.fatal(
          `Failed to calculate reading area publicationId: ${this.publicationId} view post size: ${this.viewport} Error: ${error}`,
          {
            sendError: true
          }
        );
      }
    },
    $_isReadingAreaVisible() {
      if (this.isCompilationEditMode) {
        return false;
      }

      const isHtmlStrategy = this.$store.getters[
        'PublicationStore/isHtmlStrategy'
      ];
      if (isHtmlStrategy) {
        return true;
      }
      const scrollElement = this.$store.getters[
        'PublicationStore/getScrollElement'
      ];
      const scrollRect = scrollElement?.getBoundingClientRect();
      const scrollElBottom = Math.round(scrollRect?.bottom);
      const scrollElTop = Math.round(scrollRect?.top);

      return (
        Number.isInteger(scrollElBottom) &&
        Number.isInteger(scrollElTop) &&
        scrollElBottom <= window.innerHeight &&
        scrollElTop > AppConstantsUtil.TOOLBAR_HEIGHT
      );
    },
    $_clearRetryTimeout() {
      clearTimeout(this.retryTimeOut);
    },
    $_readingAreaErrorHandler(error) {
      this.$_clearRetryTimeout();
      logger.fatal(`Error in Reading area: ${error.stack}`);
      this.$emit('readingAreaEvent', {
        type: ReadingAreaEvents.ERROR,
        data: { error }
      });
    }
  }
};
</script>
