<template>
  <div ref="vsl" class="scroll">
    <div
      :class="[wclass]"
      :style="{
        display: 'block',
        'padding-top': paddingTop + 'px',
        'padding-bottom': paddingBottom + 'px',
        height: totalItems ? 'auto' : '100%'
      }"
    >
      <!-- height: height + 'px' -->
      <slot v-if="showMetaBlock" name="meta-block" />
      <slot v-if="!scrollItems.length" name="empty-placeholder" />
      <div
        v-for="item of scrollItems"
        :key="item.paragraph"
        :disabled="item.disabled"
        :dir="bookDirection"
        :class="[
          itemClassName,
          getAudioButtonClass(item.id),
          {
            [lastParaClass]: item.isLast,
            [lastParaWithSuggestedClass]:
              item.isLast && needShownSuggestedBooks,
            [firstParaClass]: item.isFirst
          }
        ]"
        :item-index="item.index"
        @dblclick="onScrollItemClick($event, item)"
      >
        <div :dir="item.direction" :class="[itemWrapperClass, isPlaying]">
          <slot v-if="item.isFirst" name="prev-button" />
          <ParagraphMaterials
            :para-materials-type="BEFORE_PARAGRAPH"
            :para-id="item.id"
            @materialEvent="materialEventHandler"
          />
          <slot v-if="!item.isSection" name="compilation-controls" />
          <div
            v-if="!item.isSection"
            v-once
            class="book-content-item"
            v-html="item.paragraph"
          />
          <SectionView
            v-else
            :book-id="publicationId"
            :para-id="item.id"
            :content="item.paragraph"
            :client-sort-id="item.clientSortId"
            @hook:mounted="onMountedItem"
            @hook:destroyed="onDestroyedItem"
            @editControls="editHandler"
          />

          <ParagraphMaterials
            :para-materials-type="AFTER_PARAGRAPH"
            :para-id="item.id"
            @materialEvent="materialEventHandler"
          />
          <slot v-if="item.isLast" name="next-button" />
          <SuggestedBooks
            v-if="needShownSuggestedBooks && item.isLast"
          ></SuggestedBooks>
        </div>

        <slot v-if="!isViewMode" name="side-bar" />
      </div>
      <slot name="scrubber" />
      <slot name="text-selection" />
    </div>
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('VirtualScrollList.js');

import imageLoaderMixin from '@/components/views/BookScroll/imageLoaderMixin.js';
import footnoteMixin from '@/components/views/BookScroll/footnoteMixin.js';

import Locator from '@shared/publication/locator';
import Highlighter from '@shared/publication/highlighter';
import Utils from '@/services/utils/Utils';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PublicationScrollLogicService from '@/services/PublicationLogic/PublicationScrollLogicService';

import VirtualScrollListEventsEnum from '@/enums/VirtualScrollListEventsEnum';
import MaterialPositions from '@/enums/MaterialPositions';

import ParagraphMaterials from '@/components/views/ParagraphMaterials/ParagraphMaterials';
import SectionView from '@/components/views/BookScroll/SectionView';
import SuggestedBooks from '@/components/views/SuggestedBooks/SuggestedBooks.vue';

const _debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

const DEFAULT_PADDING = Math.pow(10, 6);
let stopScrollingTimeout = null;
const STOP_SCROLLING_TIMEOUT = 200;
const META_INFO_WRAPPER_SELECTOR = `.${AppConstantsUtil.META_BLOCK_WRAPPER_CLASS}`;

export default {
  name: 'VirtualScrollList',
  components: {
    ParagraphMaterials,
    SectionView,
    SuggestedBooks
  },
  mixins: [imageLoaderMixin, footnoteMixin],
  props: {
    scrollSelector: {
      type: String,
      required: false,
      default: ''
    },
    scrollClassName: {
      type: String,
      required: false,
      default: ''
    },
    viewPortHeight: {
      type: Number,
      required: true
    },
    bookDirection: {
      type: String,
      required: false,
      default: 'auto'
    },
    scrollItems: {
      type: Array,
      required: true
    },
    hasPrevBook: {
      type: Boolean,
      required: false,
      default: false
    },
    hasNextBook: {
      type: Boolean,
      required: false,
      default: false
    },
    domElementsNumber: {
      type: Number,
      required: true
    },
    startRenderCount: {
      type: Number,
      required: true
    },
    wclass: {
      type: String,
      required: false,
      default: ''
    },
    itemWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    startIndex: {
      type: Number,
      required: false,
      default: 0
    },
    startItemIndex: {
      type: Number,
      required: false,
      default: 0
    },
    debounce: {
      type: Number,
      required: false,
      default: 0
    },
    openOffset: {
      type: Number,
      required: false,
      default: 0
    },
    onscroll: {
      type: [Function, Boolean],
      required: false,
      default: false
    },
    totalItems: {
      type: Number,
      required: true,
      default: 0
    },
    itemClassName: {
      type: String,
      required: true,
      default: null
    },
    startPosition: {
      type: Number,
      required: true,
      default: 0
    },
    endPosition: {
      type: Number,
      required: true,
      default: 0
    },
    currentPlayingParaId: {
      type: String,
      required: false,
      default: ''
    },
    paraId: {
      type: String,
      required: false,
      default: null
    },
    scrollParaId: {
      type: String,
      required: false,
      default: null
    },
    requiredLocator: {
      type: String,
      required: false
    },
    publicationId: {
      type: String,
      required: false,
      default: null
    },
    showSuggestedBooks: {
      type: Boolean,
      required: false,
      default: false
    },
    isAudioPlaying: {
      type: Boolean,
      required: false
    }
  },
  data() {
    const processingOnScroll = this.debounce
      ? _debounce(this.$_processingOnScroll.bind(this), this.debounce)
      : this.$_processingOnScroll;
    return {
      processingOnScroll,
      height: 10000000,
      paddingTop: 0,
      paddingBottom: 0,
      isMounted: false,
      isMountCompleted: false,
      lastParaClass: AppConstantsUtil.LAST_PARA,
      lastParaWithSuggestedClass: AppConstantsUtil.LAST_PARA_WITH_SUGGESTED,
      firstParaClass: AppConstantsUtil.FIRST_PARA,
      BEFORE_PARAGRAPH: MaterialPositions.BEFORE_PARAGRAPH,
      AFTER_PARAGRAPH: MaterialPositions.AFTER_PARAGRAPH,
      htmlClassList: this.$store.getters[
        'ContextStore/getPlatformHtmlClassList'
      ]
    };
  },

  head() {
    const isHtmlStrategy = this.$store.getters[
      'PublicationStore/isHtmlStrategy'
    ];
    if (isHtmlStrategy) {
      return {
        htmlAttrs: {
          class: [
            ...this.htmlClassList,
            this.scrollClassName,
            this.delta.scrollBarClass
          ]
        }
      };
    }
  },

  computed: {
    isFirst() {
      if (!this.scrollItems || this.scrollItems.length === 0) {
        return true;
      }
      return this.scrollItems[0].isFirst;
    },
    needShownSuggestedBooks() {
      return !this.isViewMode && this.isMounted && this.showSuggestedBooks;
    },
    showMetaBlock() {
      return this.isFirst && !this.isViewMode;
    },
    isViewMode() {
      return this.$store.getters['OpenParameterStore/isPreviewMode'];
    },
    isSearchNavigationOpen() {
      return this.$store.getters['OpenParameterStore/isSearchNavigationOpen'];
    },
    transitionOver() {
      return this.$store.getters['PublicationStore/isTransitionOver'];
    },
    isPlaying() {
      return this.isAudioPlaying ? 'is-audio-playing' : '';
    },
    metaBlockHeight() {
      return this.$store.getters['PublicationStore/getMetaBlockHeigth'];
    }
  },

  // use changeProp to identify which prop change.
  watch: {
    async transitionOver(isOver) {
      if (isOver) {
        this.sendStableEvent(isOver);
      }
    },
    async startIndex(newStartIndex) {
      const delta = this.delta;
      this.cleanRenderDirection();
      delta.startIndex = newStartIndex; // start index.
      delta.startItemIndex = this.startItemIndex;
      delta.initScroll = false;
      if (this.isMountCompleted) {
        await this.$nextTick();
        await this.initScrollState();
      }
    },
    startPosition() {
      if (this.delta.renderDirection === 'D') {
        this.applyScrollSize();
        this.cleanRenderDirection();
      }
    },
    endPosition() {
      if (this.delta.renderDirection === 'D') {
        this.cleanRenderDirection();
      }
    },
    async scrollItems(newItems, oldItems) {
      if (!oldItems.length) {
        await this.$nextTick();
        this.delta.needRecalcSize = true;
      }
    },
    expandedFootnotesMode(isExpanded) {
      if (process.client) {
        this.$_applyFootnotesMode(isExpanded, this.$refs.vsl);
      }
    },
    requiredLocator(newVal) {
      this.localRequiredLocator = newVal;
    }
  },

  destroyed() {
    this.removeScrollClass();
  },

  created() {
    const startIndex = this.startIndex;
    const keeps = this.domElementsNumber;
    const delta = Object.create(null);
    delta.needRecalcSize = false;
    delta.direction = 'D'; // current scroll direction, D: down, U: up.
    delta.scrollTop = 0; // current scroll top, use to direction.
    delta.startIndex = startIndex; // start index.
    delta.halfKeeps = Math.round(keeps / 2);
    delta.renderDirection = '';
    delta.renderDelta = this.startRenderCount;
    delta.startItemIndex = this.startItemIndex;
    delta.items = [];
    delta.offsetBottom = 0; // cache all the scrollable offset.
    delta.offsetTop = 0; // cache all the scrollable offset.
    delta.paddingTop = DEFAULT_PADDING; // container wrapper real padding-top.
    delta.paddingBottom = 100000; // container wrapper real padding-bottom.
    delta.averageItemHeight = 0;
    delta.isFirst = false;
    delta.isLast = false;
    delta.isTooFar = false;
    delta.topSlotsNum = Math.round(keeps * 0.5);
    delta.iniTimeout = null;
    delta.allowProcessingScroll = false;
    delta.initScroll = false;
    delta.scrollBarClass = 'vertical-scroll-bar';
    delta.startInexElementRect = null;
    this.delta = delta;
  },

  async mounted() {
    this.isMounted = true;
    await this.initScrollState();
    this.addOnScrollListener();
    this.isMountCompleted = true;
  },

  async updated() {
    const delta = this.delta;
    if (this.delta.needRecalcSize) {
      this.$_recalcScrollSize();
      this.delta.needRecalcSize = false;
      return;
    }
    if (this.expandedFootnotesMode) {
      this.$_applyFootnotesMode(this.expandedFootnotesMode, this.$refs.vsl);
    }
    if (!delta.shiftUp || delta.direction !== 'U') {
      return;
    }
    this.updatePaddingOnUpRender();
    this.applyScrollSize();
    this.cleanRenderDirection();
    delta.shiftUp = null;
  },

  methods: {
    onMountedItem() {
      this.delta.needRecalcSize = true;
    },
    onDestroyedItem() {
      this.delta.needRecalcSize = true;
    },
    async $_recalcScrollSize() {
      const delta = this.delta;
      if (!delta.initScroll) {
        return;
      }
      this.initScrollSize();
      this.applyScrollSize();
    },
    addScrollBarMarkerClass() {
      const scrollElement = this.getScrollElement();
      if (!scrollElement) {
        return;
      }
      const parentWidth = scrollElement.parentElement
        ? scrollElement.parentElement.clientWidth
        : window.innerWidth;

      const scrollBarWidth = parentWidth - scrollElement.clientWidth;
      if (scrollBarWidth !== 0) {
        scrollElement.classList.add(this.delta.scrollBarClass);
      }
    },
    addOnScrollListener() {
      const scrollingElement = this.getScrollListenerElement();
      if (scrollingElement) {
        scrollingElement.addEventListener('scroll', this.onScroll);
        scrollingElement.addEventListener('wheel', this.onUserScroll);
        scrollingElement.addEventListener('touchstart', this.onUserScroll);
      }
    },
    removeScrollClass() {
      const scrollElement = this.getScrollElement();
      if (scrollElement) {
        scrollElement.classList.remove(this.delta.scrollBarClass);
      }
      const scrollingElement = this.getScrollListenerElement();
      if (scrollingElement) {
        scrollingElement.removeEventListener('scroll', this.onScroll);
      }
    },

    getScrollElement() {
      return document.querySelector(this.scrollSelector);
    },

    getScrollListenerElement() {
      if (this.scrollSelector === 'html') {
        return window;
      }
      return this.getScrollElement();
    },

    async initScrollState() {
      const delta = this.delta;
      clearTimeout(delta.iniTimeout);
      delta.direction = 'D';

      this.initScrollSize();
      this.applyScrollSize();
      const scrollElement = this.getScrollElement();
      const startItem = this.scrollItems[this.startItemIndex];
      if (startItem && !startItem.isFirst) {
        try {
          await this.$_setImageSrc(scrollElement);
        } catch (error) {
          logger.error(`Get error on load image on scroll error: ${error}`);
        }
      }
      this.$_resetHighlightAnchor(AppConstantsUtil.OPEN_ANCHOR_CLASS_NAME);

      return new Promise((resolve, reject) => {
        delta.iniTimeout = setTimeout(() => {
          try {
            this.$_applyFootnotesMode(
              this.expandedFootnotesMode,
              this.$refs.vsl
            );
            if (Number.isInteger(this.startItemIndex)) {
              this.cleanRenderDirection();
              const items = this.getItems();
              const itemIndex = this.startItemIndex;

              const anchorHeight = this.$_getAnchorHeight(items[itemIndex]);
              const topSlotsHeight = this.calcItemHeight(items, 0, itemIndex);
              let openScrollTop = delta.paddingTop + topSlotsHeight;
              if (this.startIndex > 0) {
                openScrollTop += anchorHeight;
              }
              let scrollTop = openScrollTop + this.openOffset;

              if (
                startItem?.isFirst &&
                this.isSearchNavigationOpen &&
                !delta.paddingTop
              ) {
                scrollTop += this.$_calcMetaBlockHeight();
              }

              const requiredLocatorTop = this.$_getRequiredLocatorTop();
              const requestDelta = this.$_getRequestPositionDelta(
                scrollTop,
                requiredLocatorTop
              );
              scrollTop += requestDelta;

              if (this.isChrome() && this.isIOS() && !this.isMountCompleted) {
                scrollTop += 1;
              }

              this.setScrollTop(scrollTop);
            }
            delta.allowProcessingScroll = false;
            delta.initScroll = true;
            this.addScrollBarMarkerClass();
            this.sendStableEvent(this.transitionOver);
          } catch (error) {
            logger.error(`Get error on init scroll state error: ${error}`);
            return reject(error);
          } finally {
            this.localRequiredLocator = null;
          }
          return resolve();
        }, 200);
      });
    },

    sendStableEvent(transitionOver) {
      const scrollTop = this.getScrollTop();
      const param = this.$_createScrollParams(scrollTop);
      if (transitionOver || this.isViewMode) {
        this.emit({
          type: VirtualScrollListEventsEnum.STABLE_SCROLL,
          data: param
        });
      }
    },

    materialEventHandler(payload) {
      this.emit({
        type: VirtualScrollListEventsEnum.MATERIAL_EVENT,
        data: payload
      });
    },
    editHandler(payload) {
      this.emit({
        type: VirtualScrollListEventsEnum.SECTION_EVENT,
        data: payload
      });
    },
    $_getAnchorHeight(anchorItem) {
      const paraId = this.scrollParaId || this.paraId;
      try {
        const className = AppConstantsUtil.OPEN_ANCHOR_CLASS_NAME;
        this.$_highlightAnchor(paraId, className);
        const anchor = this.$refs.vsl.querySelector(`.${className}`);
        const rect = anchor.getBoundingClientRect();
        const itemRect = anchorItem.getBoundingClientRect();
        const safeAreaInsetTop = Utils.getSafeAreaInsetTop();
        return rect.y - itemRect.y - safeAreaInsetTop;
      } catch (error) {
        logger.warn(
          `Get error on calc open anchor height by paraId: ${paraId} error:${error} return default val 0.`
        );
        return 0;
      }
    },

    $_getRequestPositionDelta(scrollTop, requiredLocatorTop) {
      const defaultVal = 0;
      if (!requiredLocatorTop) {
        return defaultVal;
      }
      const distFromTopToLocator = scrollTop - requiredLocatorTop;
      const viewportHeight =
        window.innerHeight -
        AppConstantsUtil.PROGRESS_TOOLBAR_HEIGHT -
        AppConstantsUtil.TOOLBAR_HEIGHT;

      const isAboveViewport =
        distFromTopToLocator > AppConstantsUtil.TOOLBAR_HEIGHT;
      const isBelowViewport = Math.abs(distFromTopToLocator) > viewportHeight;

      const isInsideViewport = !isAboveViewport && !isBelowViewport;

      if (isInsideViewport) {
        return 0;
      }
      if (isAboveViewport) {
        return -1 * distFromTopToLocator - AppConstantsUtil.TOOLBAR_HEIGHT;
      }
      if (isBelowViewport) {
        return requiredLocatorTop - scrollTop - viewportHeight;
      }
      return defaultVal;
    },

    $_getRequiredLocatorTop() {
      try {
        const className = 'request-anchor';
        this.$_highlightAnchor(this.localRequiredLocator, className);
        const requestAnchor = this.$refs.vsl.querySelector(`.${className}`);

        const rect = requestAnchor.getBoundingClientRect();
        const currentScrollTop = this.getScrollTop();
        return rect.y + currentScrollTop;
      } catch (error) {
        logger.warn(
          `Get error on calc requested locator top by paraId: ${this.paraId} error:${error} return default val 0.`
        );
        return 0;
      }
    },

    $_highlightAnchor(serializedLocator, className) {
      const InTextRangeLocator = Locator.deserialize(serializedLocator);
      const element = this.$refs.vsl.querySelector(
        `#${InTextRangeLocator.startLocator.prefixedParagraphId}`
      );
      const end = InTextRangeLocator.endLocator.logicalCharOffset;
      const start = InTextRangeLocator.startLocator.logicalCharOffset;
      const stableOffsets = [[start, end]];
      Highlighter.decorateStableOffsets(stableOffsets, element, className);
    },

    $_resetHighlightAnchor(className) {
      const elements = this.$refs.vsl?.querySelectorAll(`.${className}`);
      elements?.forEach(element => {
        element.classList.remove(className);
      });
    },

    initScrollSize() {
      const DEFAULT_AVARAGE_HEIGHT = 300;
      const items = this.getItems();
      const images = this.getImages();

      const delta = this.delta;
      let totalHeight = this.calcTotalItemHeight(items);
      const imageHeight = this.calcImageHeight(images);
      totalHeight += imageHeight;

      const averageItemHeight = Math.round(totalHeight / items.length);
      delta.averageItemHeight = isNaN(averageItemHeight)
        ? DEFAULT_AVARAGE_HEIGHT
        : averageItemHeight;

      delta.height = delta.averageItemHeight * this.totalItems;
      delta.paddingTop = delta.averageItemHeight * this.startPosition;
      const bottomItem = this.getBottomItem(items, delta.direction);
      const isLast =
        bottomItem && bottomItem.classList.contains(this.lastParaClass);
      delta.paddingBottom = isLast ? 0 : delta.height - delta.paddingTop;
    },

    applyScrollSize() {
      const delta = this.delta;
      this.height = delta.height;
      this.paddingTop = delta.paddingTop;
      this.paddingBottom = delta.paddingBottom;
    },

    emit(payload) {
      this.$emit('VirtualScrollListEvent', payload);
    },

    onScroll() {
      window.clearTimeout(stopScrollingTimeout);
      PublicationScrollLogicService.updateIsScrolling(this.$store, true);
      const newScrollTop = this.getScrollTop();
      const delta = this.delta;
      delta.isTooFar = this.$_isTooFar(newScrollTop);
      if (
        !delta.isTooFar &&
        (!delta.allowProcessingScroll || !delta.initScroll)
      ) {
        if (delta.initScroll) {
          delta.allowProcessingScroll = true;
        }
        this.$_setMetaBlockInViewPort(newScrollTop);
        return;
      }
      delta.direction = newScrollTop > delta.scrollTop ? 'D' : 'U';

      this.processingOnScroll(newScrollTop);

      stopScrollingTimeout = setTimeout(() => {
        this.$_setMetaBlockInViewPort(newScrollTop);

        this.emit({
          type: VirtualScrollListEventsEnum.STOP_SCROLLING,
          data: {
            isTooFar: () => {
              //TODO: replace to store for share this functionality with reading area
              const scrollTop = this.getScrollTop();
              const isTooFar = this.$_isTooFar(scrollTop);
              return isTooFar;
            }
          }
        });
      }, STOP_SCROLLING_TIMEOUT);
    },

    onUserScroll() {
      this.$store.commit(
        'PublicationStore/updateScrubberPositionLineByLineMode',
        null
      );
    },

    $_isTooFar(scrollTop) {
      const items = this.getItems();
      const outTop = this.$_isBlocksOutFromViewportTop(items);
      const outBottom = this.$_isBlocksOutFromViewportBottom(items, scrollTop);
      return outTop || outBottom;
    },

    $_isBlocksOutFromViewportTop(items) {
      if (!items[0]) {
        return false;
      }
      const outTop =
        items[0].getBoundingClientRect().top >
        Math.round(1.2 * this.viewPortHeight);
      return outTop;
    },

    $_isBlocksOutFromViewportBottom(items, scrollTop) {
      if (!items[0]) {
        return false;
      }
      const lastItemBottomPosition = this.$_getLastItemBottomPosition(items);
      const outBottom = scrollTop > lastItemBottomPosition;
      return outBottom;
    },

    $_getLastItemBottomPosition(items) {
      const lastItem = items[items.length - 1];
      return lastItem.offsetTop + lastItem.clientHeight;
    },

    $_processingOnScroll(scrollTop) {
      this.updateDeltaParams(scrollTop);
      if (this.delta.renderDirection) {
        this.updatePaddingOnDownRender();
      }

      if (this.onscroll) {
        const param = this.$_createScrollParams(scrollTop);
        this.onscroll(event, param);
      }
    },

    $_getItemSlotPositions() {
      const items = this.getItems();
      if (items.length === 0) {
        return {
          topItemSlotIndex: -1,
          bottomItemSlotIndex: -1
        };
      }
      const topItemSlotIndex = this.getSlotIndexFromItem(items[0]);
      const bottomItemSlotIndex = this.getSlotIndexFromItem(
        items[items.length - 1]
      );
      return {
        topItemSlotIndex,
        bottomItemSlotIndex
      };
    },

    getSlotIndexFromItem(item) {
      const slotIndexAttrName = 'item-index';
      return parseInt(item.getAttribute(slotIndexAttrName));
    },

    itemIsLast(item) {
      return item.classList.contains(this.lastParaClass);
    },

    itemIsFirst(item) {
      return item.classList.contains(this.firstParaClass);
    },

    $_createScrollParams(scrollTop) {
      const delta = this.delta;
      const param = Object.create(null);
      param.isTooFar = delta.isTooFar;
      param.height = delta.height;
      param.offset = scrollTop;
      param.scrollTop = delta.scrollTop;
      param.offsetBottom = delta.offsetBottom;
      param.startItemIndex = Math.max(
        delta.startItemIndex + delta.topSlotsNum,
        0
      );
      param.startIndex = delta.startIndex;
      param.renderDirection = delta.renderDirection;
      param.itemSlotPositions = this.$_getItemSlotPositions();
      return param;
    },

    updateDeltaParams(scrollTop) {
      const delta = this.delta;
      const items = this.getItems();
      if (items.length === 0) {
        return;
      }

      const isLastItem = this.itemIsLast(items[items.length - 1]);
      if (isLastItem && delta.direction === 'D') {
        this.height =
          this.$_getLastItemBottomPosition(items) +
          AppConstantsUtil.PROGRESS_TOOLBAR_HEIGHT;
        this.paddingBottom = 0;
        return;
      }
      const isFirst = this.itemIsFirst(items[0]);
      if (isFirst && delta.direction === 'U') {
        return;
      }

      const totalHeight = this.calcTotalItemHeight(items);
      delta.offsetBottom = totalHeight + delta.paddingTop;

      const topItem = this.getTopItem(items, delta.direction);
      const bottomItem = this.getBottomItem(items, delta.direction);
      const currentItemTopIndex = this.findItemIndexByItem(items, topItem);
      let currentItemBottomIndex;

      if (delta.direction === 'U') {
        delta.renderDirection =
          currentItemTopIndex < delta.renderDelta ? 'U' : '';
      } else if (delta.direction === 'D') {
        currentItemBottomIndex = this.findItemIndexByItem(items, bottomItem);
        delta.renderDirection =
          items.length - 1 - currentItemBottomIndex < delta.renderDelta
            ? 'D'
            : '';
      }

      if (delta.renderDirection) {
        delta.startIndex = this.getSlotIndexFromItem(topItem);
        if (delta.direction === 'U') {
          delta.shiftUp = delta.startItemIndex - currentItemTopIndex;
        } else if (delta.direction === 'D') {
          let shift = 0;
          shift = currentItemTopIndex - delta.startItemIndex;
          const isFromBeginStart = delta.startItemIndex < delta.halfKeeps;
          if (isFromBeginStart) {
            delta.startItemIndex = Math.min(
              Math.round((items.length + shift) / 2),
              delta.halfKeeps
            );
            shift = Math.max(0, delta.startIndex - delta.halfKeeps);
          }
          delta.shiftDown = shift;
        }
        delta.startInexElementRect = this.getIndexItemRect(delta.startIndex);
      }
      delta.scrollTop = scrollTop;
    },

    findItemIndexByItem(items, topItem) {
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        if (topItem.innerHTML === item.innerHTML) {
          return index;
        }
      }
      return null;
    },

    getAudioButtonClass(paraId) {
      return this.currentPlayingParaId === paraId
        ? 'hide-paragraph-play-buttons active'
        : '';
    },

    getTopItem(items, direction) {
      let topItem = this.$_getTopItemOnTop();
      if (!topItem) {
        topItem = this.$_getTopItemByDirection(items, direction);
      }
      return topItem;
    },

    $_getTopItemOnTop() {
      const topElement = this.findItemByPoint(
        window.innerWidth / 2,
        AppConstantsUtil.TOOLBAR_HEIGHT
      );
      return topElement;
    },

    $_getTopItemByDirection(items, direction) {
      if (direction === 'U') {
        return items[0];
      } else {
        return items[items.length - 1];
      }
    },

    getBottomItem(items, direction) {
      let bottomItem = this.$_getBottomItemOnBottom();
      if (!bottomItem) {
        bottomItem = this.$_getBottomItemByDirection(items, direction);
      }
      return bottomItem;
    },

    $_getBottomItemOnBottom() {
      const correctionBottom = 50;
      const bottomElement = this.findItemByPoint(
        window.innerWidth / 2,
        window.innerHeight - correctionBottom
      );
      return bottomElement;
    },

    $_getBottomItemByDirection(items, direction) {
      if (direction === 'D') {
        return items[items.length - 1];
      } else {
        return items[0];
      }
    },

    findItemByPoint(x, y) {
      const elements = document.elementsFromPoint(x, y);
      const item = elements.find(element => {
        return element.classList.contains('item');
      });
      return item;
    },

    getItems() {
      this.delta.items = this._getScrollItemsByQuery('.' + this.itemClassName);
      return this.delta.items;
    },

    getImages() {
      return this._getScrollItemsByQuery('img');
    },

    getIndexItemRect(itemIndex) {
      const item = this._getScrollItemsByQuery(
        `[item-index="${itemIndex}"]`
      )[0];
      if (!item) {
        logger.warn(
          `Can not find item by ${itemIndex} return defaul empty DOMRect`
        );
        return new DOMRect(0, 0, 0, 0);
      }
      return item.getBoundingClientRect();
    },

    _getScrollItemsByQuery(itemSelector) {
      if (!this.$refs.vsl) {
        return [];
      }
      return Array.prototype.slice.call(
        this.$refs.vsl.querySelectorAll(itemSelector)
      );
    },

    loadImages() {
      const self = this;
      const images = self.getNotLoadedImages();
      if (!images.length) {
        return Promise.resolve([]);
      }
      const promises = images.reduce((promisesList, image) => {
        if (!image.height) {
          promisesList.push(loadImage(image));
        }
        return promisesList;
      }, []);
      return Promise.all(promises);

      function loadImage(image) {
        return new Promise(function(resolve) {
          image.addEventListener('load', _onLoad);

          function _onLoad(event) {
            resolve(event.target);
            image.removeEventListener('load', _onLoad);
          }
        });
      }
    },

    getNotLoadedImages() {
      return Array.prototype.slice.call(
        this.$refs.vsl.querySelectorAll('img:not([loaded])')
      );
    },

    getViewPortHeight() {
      return this.$refs.vsl.getClientRects()[0];
    },

    calcTotalItemHeight(items) {
      const from = 0;
      const to = items.length;
      return this.calcItemHeight(items, from, to);
    },

    calcImageHeight(images) {
      return images.reduce((height, image) => {
        if (image) {
          const imgHeight = parseInt(image.getAttribute('height'), 10);
          height = !isNaN(imgHeight) ? height + imgHeight : height;
        }
        return height;
      }, 0);
    },

    calcItemHeight(items, from, to) {
      let height = 0;
      if (from > to) {
        return 0;
      }
      items.slice(from, to).forEach(item => {
        height += item.getBoundingClientRect().height;
        if (this.itemIsFirst(item) && this.$_getMetaBlockElement()) {
          height += this.$_calcMetaBlockHeight();
        }
        if (this.itemIsLast(item) && this.$_getSuggestedBlockElement()) {
          height += this.$_calcSuggestedBlockHeight();
        }
      });
      return height;
    },

    // set manual scroll top.
    setScrollTop(scrollTop) {
      const vsl = this.getScrollElement();
      if (vsl) {
        this.delta.scrollTop = scrollTop;
        (vsl.$el || vsl).scrollTop = scrollTop;
      }
    },

    getScrollTop() {
      const vsl = this.getScrollElement();
      if (!vsl) {
        return 0;
      }
      return (vsl.$el || vsl).scrollTop || 0;
    },

    cleanRenderDirection() {
      this.delta.renderDirection = '';
    },

    updatePaddingOnDownRender() {
      const delta = this.delta;
      let deltaPaddingTop = 0;
      if (delta.renderDirection === 'D' && delta.initScroll) {
        const items = this.getItems();
        deltaPaddingTop = items
          .slice(0, delta.shiftDown)
          .reduce((sum, item) => {
            sum += item.getBoundingClientRect().height;
            return sum;
          }, 0);
        if (deltaPaddingTop !== 0 && this.itemIsFirst(items[0])) {
          deltaPaddingTop += this.$_calcMetaBlockHeight();
        }
        delta.paddingTop += deltaPaddingTop;

        delta.paddingBottom =
          (this.totalItems - this.endPosition) * delta.averageItemHeight;

        const totalHeight = this.calcTotalItemHeight(items);
        delta.height = delta.paddingTop + totalHeight + delta.paddingBottom;
      }
    },

    $_getComputedVals(element, props) {
      const computedEl = window.getComputedStyle(element, null);
      return props.reduce((sum, prop) => {
        const strVal = computedEl.getPropertyValue(prop);
        sum += _strValToNumber(strVal);
        return sum;
      }, 0);

      function _strValToNumber(strVal) {
        const num = parseInt(strVal.replace('px'), 10);
        return isNaN(num) ? 0 : num;
      }
    },

    $_getMetaBlockElement() {
      return document.querySelector(META_INFO_WRAPPER_SELECTOR);
    },

    $_getSuggestedBlockElement() {
      return document.querySelector('.suggested-wrapper');
    },

    $_calcMetaBlockHeight() {
      const metaBlock = this.$_getMetaBlockElement();
      if (!metaBlock) {
        return 0;
      }
      const computedVlas = this.$_getComputedVals(metaBlock, ['margin-bottom']);
      return metaBlock.getBoundingClientRect().height + computedVlas;
    },

    $_calcSuggestedBlockHeight() {
      const suggestedBlock = this.$_getSuggestedBlockElement();
      return suggestedBlock.getBoundingClientRect().height;
    },

    updatePaddingOnUpRender() {
      const delta = this.delta;

      const newStartIndexRect = this.getIndexItemRect(delta.startIndex);
      const newAveragePaddingTop = delta.averageItemHeight * this.startPosition;
      const scrollDelta = this.delta.paddingTop - newAveragePaddingTop;
      const scrollTop = this.getScrollTop();
      const startIndexDelta = Math.round(
        newStartIndexRect.top - delta.startInexElementRect.top
      );

      const newScrollTop = scrollTop - scrollDelta + startIndexDelta;
      delta.paddingTop = newAveragePaddingTop;
      this.setTopPadding(delta.paddingTop);
      this.setScrollTop(newScrollTop);
      delta.allowProcessingScroll = false;
    },

    setTopPadding(paddingTop) {
      const scroll =
        this.$refs.vsl && this.$refs.vsl.querySelector('.' + this.wclass);
      if (!scroll) {
        return;
      }
      scroll.style.paddingTop = `${paddingTop}px`;
    },

    onScrollItemClick(e, item) {
      if (item.disabled) {
        e.stopImmediatePropagation();
      }
    },
    $_setMetaBlockInViewPort(newScrollTop) {
      this.$store.commit(
        'ProgressStore/setMetaBlockInViewPort',
        newScrollTop <= this.metaBlockHeight
      );
    },
    isChrome() {
      return /CriOS/.test(navigator.userAgent);
    },

    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  }
};
</script>
<style src="./VirtualScrollList.less" lang="less"></style>
<style lang="less">
@narrow-t: ~'all and (min-width: 0px) and (max-width: 1000px)';

.book-scroll-block {
  .item.is-last {
    padding-bottom: 88px;

    @media print {
      padding-bottom: 175px;
    }
  }
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 64px - 40px);

  // @supports (max-height: max(0px)) {
  //   @media (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  //     max-height: calc(
  //       max(100vh, env(safe-area-inset-top)) -
  //         max(94px, env(safe-area-inset-top)) -
  //         max(40px, env(safe-area-inset-top))
  //     );
  //   }
  // }

  .item {
    position: relative;
    // z-index: 1;
    box-sizing: border-box;
    display: flex;
    padding: 0;
    margin: 0 auto;

    .margin-notes-on & {
      &:not(.last-para-with-suggested) {
        @media (min-width: 1366px) {
          --sidebarWidth: 250px;
          --overlap: 32px;
          max-width: calc(
            var(--reading-area-wrapper) + (var(--sidebarWidth) - var(--overlap)) *
              2
          );
          padding-inline-start: calc(var(--sidebarWidth) - var(--overlap));
        }
      }
      .extras-is-open & {
        @media (min-width: 1366px) {
          max-width: var(--reading-area-wrapper);
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    &:not(.meta-item):last-of-type:not(.last-para-with-suggested) {
      display: grid;
      grid-template-columns: 100% 0;
      grid-template-rows: auto auto;

      .margin-notes-on & {
        grid-template-columns: calc(100% - 250px + 32px) 0;
      }
    }

    &:not(.last-para-with-suggested) {
      max-width: var(--reading-area-wrapper);
    }

    &.meta-item {
      align-items: center;
      flex-direction: column;
      max-width: 100%;
      margin: 0;
      z-index: 3;

      .item-wrapper {
        width: 100%;
        max-width: var(--reading-area-wrapper);
        margin-top: 40px;
      }
    }

    &.is-first {
      --first-item-shift: 1em;

      padding-top: var(--first-item-shift);

      @media @narrow-t {
        --first-item-shift: 3em;
      }

      .compilation-controls,
      .move-controls,
      .section-controls {
        top: var(--first-item-shift);
      }
    }

    &.is-last {
      .item-wrapper {
        max-width: 100%;
      }
    }

    &.last-para-with-suggested {
      min-height: 500px;
    }

    .item-wrapper {
      flex-grow: 1;
      padding: 0 24px;

      @media all and (min-width: 0) and (max-width: 1000px) {
        padding: 0;
      }

      .compilation-book & {
        margin-bottom: 1.5em;
        display: flex;
        padding-top: 16px;
        margin-left: 20px;
        margin-right: 20px;
        max-width: calc(100% - 20px - 20px);

        @media all and (max-width: 700px) {
          margin-left: 10px;
          margin-right: 10px;
          max-width: calc(100% - 10px - 10px);
        }

        .compilation-edit& {
          margin-bottom: 2.7em;

          &:after {
            content: '';
            display: block;
            border-bottom: 1px dashed #cbd5e1;
            position: absolute;
            bottom: 28px;
            left: 20px;
            right: 20px;
          }
        }
      }

      .compilation-edit & {
        border-radius: 8px;
        box-shadow: 0 0 0 1px #cbd5e1, 0px 4px 8px rgba(176, 190, 197, 0.24);
        background-color: #ffffff;

        @media all and (min-width: 0) and (max-width: 768px) {
          padding-top: 45px;
        }

        .night-theme-template& {
          background-color: #262626;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.63);
        }
      }
    }

    .book-content-item {
      flex-grow: 1;
      max-width: 100%;

      @media print {
        page-break-inside: avoid;
      }

      div[class*='ilm-'],
      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        &:first-of-type {
          position: relative;
          z-index: 1;
        }
      }

      svg {
        fill: var(--primary-color);
      }

      .ico-arrow-front-long {
        @media print {
          display: none;
        }
      }
    }

    .pub-nav {
      box-sizing: border-box;
      display: block;
      width: 185px;
      height: 40px;
      padding: 0 20px;
      margin: 20px auto;
      color: var(--primary-color);
      font-size: var(--h2FontSize);
      font-weight: 300;
      line-height: 40px;
      text-align: center;
      white-space: nowrap;
      background: transparent;
      box-shadow: none;
      border: 1px solid var(--primary-color);
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background: transparent;
        opacity: 0.8;
      }
    }

    &[disabled] {
      .itm-wrap,
      .block-num,
      .img-par,
      .chapter-text {
        color: transparent;
        text-shadow: 0 0 3px rgb(30, 41, 59);

        .night-theme-template & {
          text-shadow: 0 0 3px rgb(172, 172, 172);
        }

        .search-sentence {
          color: transparent;

          .night-theme-template & {
            text-shadow: 0 0 3px #fff;
          }
        }
      }
    }
  }
}
</style>
